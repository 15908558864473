import * as React from 'react';

import * as style from 'styles/components/utils/pageBuilder/mediaTextBlock.module.scss';
import ImageGatsby from 'gatsby-plugin-sanity-image';
import TextEditorRender from 'components/utils/TextEditorRender';

const MediaTextBlock = ({ _key, _type, ...content }) => {

    const orientation = content.orientation ? content.orientation : 'imageLeft';
    return (
        <div key={_key} className={`wrapper ${style.mediaTextBlock}`} id={_key}>
            <div className={`${style.mediaTextBlock__media} ${style[`mediaTextBlock__media__${orientation}`]}`}>
                <div>
                    <ImageGatsby
                        {...content.image.image}
                        height={800}
                        alt={content.alt}
                        title={content.title}
                    />
                </div>
            </div>
            <div className={`mediaTextBlock__content ${style[`mediaTextBlock__content__${orientation}`]}`}>
                {content.title && <h2 className="title-xs-mobile title-md semibold">{content.title}</h2>}
                {content.title && <TextEditorRender content={content.body} />}
            </div>
        </div>
    );
}

export default MediaTextBlock;
