import * as React from 'react';

import * as style from 'styles/components/utils/pageBuilder/textBlock.module.scss';
import TextEditorRender from 'components/utils/TextEditorRender';

const TextBlock = ({ _key, _type, ...content }) => (
    <div key={_key} className={`wrapper-s ${style.textBlock}`} id={_key}>
        <TextEditorRender content={content.body} />
    </div>
);

export default TextBlock;
