import * as React from 'react';

import * as style from 'styles/components/utils/pageBuilder/staffListBlock.module.scss';
import StaffList from 'components/misc/StaffList';

const StaffListBlock = ({ _key, _type, ...content }) => (

    <div className={style.staffListBlock}>
        <div className="wrapper" id={_key}>
            {content.title && <h2 className="title-xs-mobile title-md semibold">{content.title}</h2>}
            <StaffList members={content.staffMembers} />
        </div>
    </div>
);

export default StaffListBlock;
