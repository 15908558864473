import * as React from 'react';

import * as style from 'styles/components/utils/pageBuilder/heroBlock.module.scss';
import Image from 'components/utils/Image';

const HeroBlock = ({ _key, _type, ...content }) => {

    const heroType = content.heroType ? content.heroType : 'full';
    // const heroClass = style[`heroBlock__${heroType}`];
    // const imageClass = style[`heroBlock__${heroType}__image`];
    // const heroWrapperClass = style[`heroBlock__${heroType}__wrapper`];

    const headerImage = content.image && content.image;

    return (
        <div className={style.heroBlock}>
            {headerImage ? (
                <div className={`${style[`heroBlock__${heroType}`]} ${heroType === 'full' ? '' : 'wrapper-s'}`}>
                    <div className={`${style[`heroBlock__${heroType}__image`]}`}>
                        <Image image={headerImage} width="2000" />
                    </div>
                    <div className={`${style[`heroBlock__${heroType}__content`]}`}>
                        <div className={heroType === 'full' ? 'wrapper-s' : ''}>
                            <h1 className="title-sm-mobile title-xl text-bold">{content.title}</h1>
                        </div>
                    </div>
                </div>
            ) : (
                <div className={style.heroBlock__noImage}>
                    <div className="wrapper-s">
                        <h1 className="title-sm-mobile title-xl text-bold">{content.title}</h1>
                    </div>
                </div>
            )}
        </div>
    )
}

export default HeroBlock;

/*
<div className={heroClass} id={_key}>
<div className={heroWrapperClass}>
    scoot
</div>
{content.image && content.image.image && (
    <Image image={content.image} width="2000" />
)}
</div>
*/
