import * as React from 'react';

import * as style from 'styles/components/utils/pageBuilder/stickyMenuBlock.module.scss';

const StickyMenuBlock = ({ _key, _type, ...content }) => {
    console.log('STICKY MENU BLOCK');
    // console.log(content);

    return (
        <nav className={`${style.stickyMenuBlock} bg`}>
            <ul>
                {content.page.map((item) => (
                    <li key={item._key}>
                        <a href={`${item.page.slug.current}`}>{item.title}</a>
                    </li>
                ))}
            </ul>
        </nav>
    )
}

export default StickyMenuBlock;
