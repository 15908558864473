import * as React from 'react';

import TextEditorRender from 'components/utils/TextEditorRender';
import PageBuilder from 'components/utils/pageBuilder/PageBuilder';

const Basic = ({ pageContext }) => {
  const { page } = pageContext;
  let content;
  if (page.content?._rawPageContent) {
    content = (
        <PageBuilder content={page.content._rawPageContent} />
    );
  } else if (page.content?._rawContent) {
    content = (
      <div className="wrapper-s">
        <TextEditorRender content={page.content._rawContent} />
      </div>
    );
  } else {
    content = null;
  }

  return (
    <div className="basic-wrapper">
      {content}
    </div>
  );
};

export default Basic;
