import * as React from 'react';

import TextBlock from 'components/utils/pageBuilder/TextBlock';
import MediaTextBlock from 'components/utils/pageBuilder/MediaTextBlock';
import MediaCollectionBlock from 'components/utils/pageBuilder/MediaCollectionBlock';
import StaffListBlock from 'components/utils/pageBuilder/StaffListBlock';
import HeroBlock from 'components/utils/pageBuilder/HeroBlock';
import StickyMenuBlock from 'components/utils/pageBuilder/StickyMenuBlock';

const PageBuilder = ({ content }) => {
    if(!Array.isArray(content)) {
        return null;
    }
    return (
        <>
            {content.map((block, index) => {
                switch (block._type) {
                    case 'textBlock':
                        return <TextBlock key={index} {...block} />;
                    case 'mediaTextBlock':
                        return <MediaTextBlock key={index} {...block} />;
                    case 'mediaCollectionBlock':
                        return <MediaCollectionBlock key={index} {...block} />;
                    case 'staffListBlock':
                        return <StaffListBlock key={index} {...block} />;
                    case 'heroBlock':
                        return <HeroBlock key={index} {...block} />;
                    case 'stickyMenuBlock':
                        return <StickyMenuBlock key={index} {...block} />;
                    default:
                        return null;
                }
            })}
        </>
    );
}

export default PageBuilder;
