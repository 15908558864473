import * as React from 'react';

import * as style from 'styles/components/utils/pageBuilder/mediaCollectionBlock.module.scss';
import MediaBlock from 'components/utils/pageBuilder/MediaBlock';

const MediaCollectionBlock = ({ _key, _type, ...content }) => {
    const numBlocks = content.mediaBlocks.length;

    return (
        <div className={`wrapper block ${numBlocks === 1 ? 'wrapper-s' : ''}`} id={_key}>
            <div className={`${style.mediaBlocks} ${style[`mediaBlocks__columns${numBlocks}`]}`} id={_key}>

                {content.mediaBlocks.map((block) => (
                    <MediaBlock key={block._key} {...block} />
                ))}
            </div>
        </div>
    )
}

export default MediaCollectionBlock;
