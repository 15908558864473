import * as React from 'react';

import ImageGatsby from 'gatsby-plugin-sanity-image';
import * as style from 'styles/components/utils/pageBuilder/mediaBlock.module.scss';

const MediaBlock = ({ _key, _type, ...content }) => {

    const mediaType = content.mediaType ? content.mediaType : 'image';
    const externalUrl = content.externalUrl ? content.externalUrl : null;
    const altText = content?.title ? content.title : 'Media block';

    return (
        <div className={`block ${style.mediaBlock}`} id={_key}>
            <div className={style.mediaBlock__media}>
                {mediaType === 'image' && (
                    <ImageGatsby
                        {...content.image.image}
                        height={800}
                        alt={altText}
                        title={altText}
                    />
                )}
                {mediaType === 'video' && (
                    <iframe
                    src={content.video.url}
                    title={altText}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    />
                )}
            </div>
            {externalUrl ? (
                <a href={externalUrl} target="_blank" rel="noreferrer">
                    <h3 className='title-xs text-semibold'>{content.title}</h3>
                    <p>{content.content}</p>
                </a>
            ) : (
                <>
                    <h3 className='title-xs text-semibold'>{content.title}</h3>
                    <p>{content.content}</p>
                </>
            )}
        </div>
    )
}

export default MediaBlock;
